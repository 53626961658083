@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@300;600;700&display=swap');

@import "tailwindcss/base";
@import "base.css";

@import "tailwindcss/components";
@import "components/header.css";
@import "components/bg.css";
@import "components/link.css";
@import "components/title.css";
@import "components/card.css";
@import "components/pagination.css";
@import "components/list.css";
@import "components/form.css";
@import "components/swiper.css";
@import "components/barba.css";

@import "tailwindcss/utilities";
@import "utilities/text.css";